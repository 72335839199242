import { PageContent } from "components/layout/PageContent/PageContent";
import { FormBlock, UploadNotification } from "components/modules";
import { THIRD_PARTY_REPORTS_IMPORT_API } from "const";
import { usePostQuery } from "hooks/usePostQuery";
import { useState } from "react";
import {
  getUploadStatus,
  getUploadStatusStorage,
  getFileName,
  getInitialFileName,
  getLoading,
  encodeDataToUrl,
} from "utils";
import { useLocalStorage } from "hooks/useLocalStorage";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { useFormSelectFile } from "hooks/useFormSelectFile";
import { useFormSelectDate } from "hooks/useFormSelectDate";
import moment from "moment";

export const ThirdPartyReports = () => {
  const pageName = "3rd_party";

  const {
    value: selectedFile,
    handleFileUpload,
    handleFileRemove,
  } = useFormSelectFile(() => setShowUploadNotification(false));

  const {
    value: startDate,
    handleDateChange,
    resetDate,
  } = useFormSelectDate(moment().subtract(1, "months").endOf("month").toDate());

  const [showUploadNotification, setShowUploadNotification] = useState(
    localStorage.getItem(pageName) || false
  );

  const [succeedFileName, setSucceedFileName] = useState("");

  const { changeStorage } = useLocalStorage(pageName, () => {
    setSucceedFileName(getInitialFileName(pageName));
  });

  const { loading, error, postFormData, responseStatus } = usePostQuery(
    THIRD_PARTY_REPORTS_IMPORT_API,
    changeStorage
  );

  const resetValues = () => {
    handleFileRemove();
    resetDate();
  };

  const handleImport = async () => {
    try {
      setShowUploadNotification(true);
      const requestData = new FormData();

      selectedFile && requestData.append("file", selectedFile);

      const startDateFormatted = moment(startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      const query = encodeDataToUrl({
        date: startDateFormatted,
      });

      const result = await postFormData(
        requestData,
        query,
        pageName,
        selectedFile?.name
      );
      if (result?.status) {
        resetValues();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const status = getUploadStatus(loading, responseStatus);

  const renderRightContent = () => {
    return (
      <UploadNotification
        status={getUploadStatusStorage(pageName, status)}
        fileName={getFileName(
          pageName,
          selectedFile?.name || "",
          status,
          succeedFileName
        )}
        closeNotification={() => setShowUploadNotification(false)}
        pageName={pageName}
      />
    );
  };
  return (
    <PageContent title="3rd Party Valuation Report" path="Imports">
      <PageFormLayout
        rightContent={showUploadNotification ? renderRightContent() : null}
      >
        <>
          <FormBlock
            fields={{
              file: {
                oneInRow: true,
                onRemove: handleFileRemove,
                file: selectedFile,
                setFile: handleFileUpload,
              },
              startDate: {
                startDate,
                fieldName: "Valuation Date",
                setStartDate: (value: Date | undefined | null) =>
                  handleDateChange(value),
                oneInRow: true,
              },
            }}
            importButton={{
              disabled: !selectedFile,
              loading: getLoading(loading, pageName),
              loadingText: "IMPORTING...",
              onclick: handleImport,
            }}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
